const IWantPerform: React.FC = () => {
  return (
    <section id="perform" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-bold text-center mb-5 mt-5">我要上台</h2>
      <div className="flex flex-col items-center">
        <div className="w-full mb-6">
          <p className="text-gray-700 text-center">
            那你还等什么呢？现在就拿出微信，添加新朋友：顶好喜剧小助手，WeChat
            ID：DingHoComedy001，或者扫下面这个二维码吧。
          </p>
          <p className="text-gray-700 text-center">我们就等你了!</p>
        </div>
        <div className="w-full sm:w-1/2 flex justify-center items-center">
          <img
            src="/img/dingho-wechat.jpeg"
            alt="Comedy Performance"
            className="rounded-lg max-w-full h-auto object-cover"
          />
        </div>
      </div>
    </section>
  );
};

export default IWantPerform;
