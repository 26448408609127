import React, { useEffect, useState } from "react";
import { X, Menu, ChevronLeft, ChevronRight } from "lucide-react";
import Modal from "./Modal";

const HeroCarousel: React.FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const images = [
    "/hero-images/2.jpg",
    "/hero-images/3.jpg",
    "/hero-images/4.jpg",
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prev) => (prev + 1) % images.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const [modalImageIndex, setModalImageIndex] = useState<number>(0);

  const handleOpenModal = (imageIndex: number) => {
    setSelectedImage(images[imageIndex]);
    setModalImageIndex(imageIndex);
  };

  const handleModalNext = () => {
    const nextIndex = (modalImageIndex + 1) % images.length;
    setSelectedImage(images[nextIndex]);
    setModalImageIndex(nextIndex);
  };

  const handleModalPrev = () => {
    const prevIndex = (modalImageIndex - 1 + images.length) % images.length;
    setSelectedImage(images[prevIndex]);
    setModalImageIndex(prevIndex);
  };

  const scrollToEvents = () => {
    document.querySelector("#events")?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div
        className="relative w-full h-[70vh] overflow-hidden"
        onClick={() => handleOpenModal(currentImageIndex)}
      >
        {images.map((src, idx) => (
          <div
            key={idx}
            className={`absolute inset-0 transition-opacity duration-1000 ${
              idx === currentImageIndex ? "opacity-100 z-10" : "opacity-0 z-0"
            }`}
            style={{
              backgroundImage: `url(${src})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        ))}

        <div className="absolute inset-0 bg-black/30 z-20 pointer-events-none" />

        <div className="relative z-30 flex flex-col items-center justify-center h-full text-white text-center px-4 pointer-events-none">
          <p className="font-custom-muyao text-3xl md:text-6xl mb-6 font-extrabold pointer-events-none">
            喜剧看顶好 生活没烦恼
          </p>
          <button
            onClick={(e) => {
              e.stopPropagation();
              scrollToEvents();
            }}
            className="bg-blue-600 hover:bg-blue-700 text-white text-2xl font-bold py-3 px-6 rounded-lg transition-colors pointer-events-auto"
          >
            点我购票
          </button>
        </div>
      </div>

      <Modal isOpen={!!selectedImage} onClose={() => setSelectedImage(null)}>
        {selectedImage && (
          <div className="relative">
            <button
              onClick={handleModalPrev}
              className="absolute left-4 top-1/2 -translate-y-1/2 bg-black/50 text-white p-2 rounded-full z-50"
            >
              <ChevronLeft size={24} />
            </button>
            <button
              onClick={handleModalNext}
              className="absolute right-4 top-1/2 -translate-y-1/2 bg-black/50 text-white p-2 rounded-full z-50"
            >
              <ChevronRight size={24} />
            </button>
            <img
              src={selectedImage}
              alt="Selected image"
              className="w-auto h-auto max-w-[90vw] max-h-[90vh] object-contain rounded-lg"
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default HeroCarousel;
