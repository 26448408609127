import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight, X, Calendar, Menu } from "lucide-react";
import Navigation from "./components/Navigation";
import HeroCarousel from "./components/HeroCarousel";
import IWantPerform from "./components/IWantPerform";
import Footer from "./components/Footer";

// Types and Interfaces
interface Event {
  id: number;
  name: string;
  description: string;
  location: string;
  images: string[];
  startTime: string;
  status: "active" | "inactive";
  ebTicketLink: string;
  stripeTicketLink: string;
  promoString?: string;
  anchorId?: string;
}

interface ImageCarouselProps {
  images: string[];
  isGreyedOut: boolean;
}

interface TicketCardProps {
  event: Event;
}

// Mock data hook
const useEventData = (): Event[] => {
  const [events] = useState<Event[]>([
    {
      id: 1,
      name: "03/28 成长的烦恼 主题开放麦",
      description: `免费停车，EVENT FREE PARKING!!!

你还记得小时候的梦想吗？
我们以为长大后能躺着赚钱，
结果长大后只能坐着办公、站着通勤、跪着求饶。
学习、工作、家庭、爱情……
每一关都像打Boss，关键还没有存档点。
我们在焦虑中成长，在崩溃中成熟，
笑一笑，好像也就没那么难过了？
这一次，我们邀请了一群不愿好好长大的脱口秀演员，
用最犀利的段子、最真实的故事，
带你笑看成长路上的鸡飞狗跳！

📅 时间：03/28/2025 6:15pm
📍 地点：1035 Cambridge St MA 02141

开放麦是什么：

开放麦是脱口秀的一种形式，也是演员打磨段子的场所。也是各种新鲜的喜剧形式的试验场，你可以在这里见证未来的脱口秀明星的成长哦。

注意事项：

现场不建议带儿童
活动不可以录音录像
演出开始前72小时无法退票
      `,
      images: ["img/openmic-0328/成长的烦恼.png"],
      startTime: "2025-03-28T18:15:00",
      location: "1035 Cambridge St MA 02141",
      status: "active",
      ebTicketLink: "",
      stripeTicketLink: "https://buy.stripe.com/8wM7wr2q27KR9hKeVa",
    },
    {
      id: 2,
      name: "04/11 顶好喜剧开放麦",
      description: `开放麦是什么：

开放麦是脱口秀的一种形式，也是演员打磨段子的场所。也是各种新鲜的喜剧形式的试验场，你可以在这里见证未来的脱口秀明星的成长哦。

注意事项：

现场不建议带儿童
活动不可以录音录像
演出开始前72小时无法退票
      `,
      images: [
        "img/openmic-poster-web.png",
      ],
      startTime: "2025-04-11T19:15:00",
      location: "1035 Cambridge St MA 02141",
      status: "active",
      ebTicketLink: "",
      stripeTicketLink: "https://buy.stripe.com/5kA7wraWy8OV79C28p",
    },
  ]);

  return events;
};

// Image Carousel Component
const ImageCarousel: React.FC<ImageCarouselProps> = ({
  images,
  isGreyedOut,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleNext = (): void => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = (): void => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="relative h-full min-h-[450px] group">
      <div
        className={`absolute inset-0 transition-opacity duration-300 ${
          isGreyedOut ? "grayscale opacity-50" : ""
        }`}
      >
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          className="w-full h-full object-contain rounded-l-lg"
        />
      </div>
      <button
        onClick={handlePrev}
        className="absolute left-0 top-1/2 -translate-y-1/2 p-2 bg-black/30 text-white opacity-0 group-hover:opacity-100 transition-opacity"
      >
        <ChevronLeft size={24} />
      </button>
      <button
        onClick={handleNext}
        className="absolute right-0 top-1/2 -translate-y-1/2 p-2 bg-black/30 text-white opacity-0 group-hover:opacity-100 transition-opacity"
      >
        <ChevronRight size={24} />
      </button>
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
        {images.map((_, idx) => (
          <button
            key={idx}
            className={`w-2 h-2 rounded-full ${
              idx === currentIndex ? "bg-white" : "bg-white/50"
            }`}
            onClick={() => setCurrentIndex(idx)}
          />
        ))}
      </div>
    </div>
  );
};

// Ticket Card Component
const TicketCard: React.FC<TicketCardProps> = ({ event }) => {
  const startDate = new Date(event.startTime);
  const now = new Date();
  const isExpired = startDate < now;
  const formattedDate =
    startDate.toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZone: "America/New_York",
    }) + " EST";

  return (
    <div
      id={event.anchorId}
      className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg overflow-hidden mb-4"
    >
      <div className="w-full md:w-1/2">
        <ImageCarousel
          images={event.images}
          isGreyedOut={event.status === "inactive"}
        />
      </div>
      <div className="w-full md:w-1/2 p-4 flex flex-col justify-between">
        <div>
          <h3 className="text-2xl font-bold mb-2">{event.name}</h3>
          <p className="text-gray-600 mb-2">
            <span className="font-bold">时间</span>: {formattedDate}
          </p>
          <p className="text-gray-600 mb-2">
            <span className="font-bold">地点</span>:
            <a
              href="https://maps.app.goo.gl/igm3MqAadF8zsEQq5"
              target="_blank"
              className="text-blue-600 hover:underline ml-1"
            >
              {event.location}
            </a>
          </p>
          {!!event.promoString && (
            <p className="text-gray-600 mb-2">
              <span className="font-bold">Coupon</span>: {event.promoString}
            </p>
          )}

          <div className="overflow-y-auto pr-4 max-h-[200px] mb-4">
            <p className="text-gray-700 whitespace-pre-line">
              {event.description}
            </p>
          </div>
        </div>

        {!isExpired && (
          <div className="flex flex-col gap-4 mt-2">
            {!!event.stripeTicketLink && (
              <button
                className={`flex-1 px-6 py-3 rounded-lg text-white ${
                  event.status === "active"
                    ? "bg-blue-600 hover:bg-blue-700"
                    : "bg-gray-400 cursor-not-allowed"
                }`}
                disabled={event.status === "inactive"}
                onClick={() => (window.location.href = event.stripeTicketLink)}
              >
                {event.status === "active"
                  ? "Buy with Stripe（无手续费）"
                  : "Sold Out"}
              </button>
            )}

            {!!event.ebTicketLink && (
              <a
                href={event.ebTicketLink}
                className="text-center text-blue-600 hover:underline"
              >
                Buy on Eventbrite
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

// Placeholder Card Component
const PlaceholderCard: React.FC = () => {
  return (
    <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg overflow-hidden mb-8">
      <div className="w-full md:w-1/2 bg-gray-100 flex items-center justify-center">
        <Calendar className="w-24 h-24 text-gray-300" />
      </div>
      <div className="w-full md:w-1/2 p-6 flex flex-col justify-center items-center text-center">
        <h3 className="text-2xl font-bold mb-4">更多演出，敬请期待</h3>
        <p className="text-gray-600 mb-6">
          We're planning exciting events for the future. Come back for updates!
        </p>
      </div>
    </div>
  );
};

const AboutUs: React.FC = () => {
  return (
    <section id="about" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-bold text-center mb-5">关于我们</h2>

      <div className="flex flex-col md:flex-row items-center">
        <div className="w-full md:w-3/5 pr-0 md:pr-12">
          <p className="text-gray-700 leading-relaxed">
            Dingho Comedy
            是一个充满激情的脱口秀社区，致力于为喜剧爱好者和表演者创造一个开放、包容的平台。
            我们相信幽默是连接人们的桥梁，能够打破隔阂，传递快乐和洞察。
            从业余选手到专业喜剧人，每个人都有机会在我们的舞台上绽放自我。
          </p>
          <br />
          <p className="text-gray-700 leading-relaxed">
            欢迎来线上关注我们！
            <br />- 小红书：
            <a
              href="https://www.xiaohongshu.com/user/profile/618c348b00000000100055c6"
              className="text-center text-blue-600 hover:underline"
            >
              顶好喜剧dhcomedy
            </a>
            <br />- 微信公众号：顶好喜剧
            <br />- 微信小助手：DingHoComedy001
            <br />- Instagram：
            <a
              href="https://www.instagram.com/dingho_comedy/"
              className="text-center text-blue-600 hover:underline"
            >
              dingho_comedy
            </a>
          </p>
        </div>
        <div className="w-full md:w-2/5 flex justify-center items-center mt-8 md:mt-0">
          <img
            src="/img/audience-2.jpeg"
            alt="Dingho Comedy Team"
            className="rounded-lg max-w-full h-auto object-cover"
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center">
        <div className="w-full md:w-3/5 pl-0 md:pl-12 md:order-2">
          <p className="text-gray-700 leading-relaxed">
            在 Dingho Comedy，我们不仅仅是一个喜剧表演平台，更是一个创意孵化器。
            我们鼓励每一位喜剧爱好者尝试、成长，将内心最真实的声音通过幽默展现出来。
            无论你是第一次站在舞台上，还是已经是经验丰富的脱口秀演员，这里都是你的家。
          </p>
        </div>
        <div className="w-full md:w-2/5 flex justify-center items-center mb-8 md:mb-0 md:order-1">
          <img
            src="/img/audience-1.jpeg"
            alt="Comedy Performance"
            className="rounded-lg max-w-full h-auto object-cover"
          />
        </div>
      </div>
    </section>
  );
};

// Main App Component
const App: React.FC = () => {
  const events = useEventData();

  // Add this useEffect to handle hash-based navigation
  useEffect(() => {
    // Get the hash from the URL (excluding the # symbol)
    const hash = window.location.hash.substring(1);

    // If there's a hash and an element with that ID exists
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        // Add a small delay to ensure the page has loaded
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    }
  }, []); // Empty dependency array means this runs once on mount

  const filteredEvents = events.filter((event) => {
    const startDate = new Date(event.startTime);
    const now = new Date();
    const isMoreThanWeekAgo =
      now.getTime() - startDate.getTime() > 1 * 24 * 60 * 60 * 1000;
    return !isMoreThanWeekAgo;
  });

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />

      {/* Hero Section */}
      <HeroCarousel />

      {/* Events Section */}
      <section
        id="events"
        className="py-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <h2 className="text-3xl font-bold mb-8 text-center">近期演出</h2>
        <div className="grid md:grid-cols-2 gap-4">
          {filteredEvents.map((event) => (
            <TicketCard key={event.id} event={event} />
          ))}
          <PlaceholderCard />
        </div>
      </section>

      <AboutUs />

      <IWantPerform />

      <Footer />
    </div>
  );
};

export default App;
