import React, { useState } from "react";
import { X, Menu } from "lucide-react";

// Navigation Item type
interface NavItem {
  label: string;
  href: string;
}

const Navigation = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Navigation Items
  const navItems: NavItem[] = [
    { label: "近期演出", href: "#events" },
    { label: "关于我们", href: "#about" },
    { label: "我要上台", href: "#perform" },
  ];

  return (
    <>
      {/* Navigation */}
      <nav className="bg-transparent absolute top-0 left-0 right-0 z-40">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <div className="flex-shrink-0">
              <img
                src="/img/logo_white_transparent.png"
                alt="logo"
                className="h-8 rounded"
              />
            </div>
            <div className="hidden md:flex space-x-8">
              {navItems.map((item) => (
                <a
                  key={item.href}
                  href={item.href}
                  className="text-white font-bold hover:text-gray-200"
                >
                  {item.label}
                </a>
              ))}
            </div>
            {/* Mobile Menu Button */}
            <div className="md:hidden">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="text-white"
              >
                {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Menu Dropdown */}
      {isMobileMenuOpen && (
        <div className="md:hidden fixed top-16 right-0 z-50 w-22">
          <div className="bg-white/20 backdrop-blur-sm rounded-lg shadow-lg">
            <div className="py-1">
              {navItems.map((item) => (
                <a
                  key={item.href}
                  href={item.href}
                  className="block px-4 py-2 text-white hover:bg-white/30 text-right"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {item.label}
                </a>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navigation;
