const footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-12 mt-16">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <p className="text-center text-gray-400">
        © {new Date().getFullYear()} Dingho Comedy, Inc. All rights
        reserved.
      </p>
    </div>
  </footer>
  );
}
export default footer;